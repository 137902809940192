<template>
	<div>
		<div>
			<div class="fix l-0 t-0 w100 z-i-10">
				<headertop/>
				<headers/>
			</div>
			<div :class="$store.state.app.showguanggao?'h-184':'h-154'"></div>
		</div>
		
		<div class="details" id="details" v-cloak>
			<div class="pwidth">
				<div class="  arrow m-t-10 m-b-10 relative">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>你所在的位置： <a href="/#/index">首页</a>
						</el-breadcrumb-item>
						<el-breadcrumb-item> <router-link to="goodslist">商品列表</router-link>
						</el-breadcrumb-item>
						<el-breadcrumb-item><span class="cursor">商品详情</span></el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<div class="bg-f detailsimg">
					<!-- 图片放大 -->
					<div class="preview">
						<div class="pic-box bigImg relative" style="pic-box:width:450px;height:450px">
							<PicZoom :url="imageurl" :scale="3"></PicZoom>
						</div>
						<!-- 小图 -->
						<div class="w100 o-x h-100">
							<ul class="min-img start h-80 " v-if=" goodsinfodata.imagesUrlList" :style="{width: goodsinfodata.imagesUrlList.length*100 + 'px'}">
								<li class="m-r-15 " v-for="(item, index) in goodsinfodata.imagesUrlList" :key="index" :class="{ '--b-a': btnimgindex == index }" @click="bigimageurl(item, index)" >
									<img :src="item" width="100%" height="100%" />
								</li>
							</ul>
						</div>
						
					</div>

					<div class="preview-r relative" style="width: 710px;">
						<div class="flex">
							<div >
								<span class="blod l-h-28 f-16">{{ goodsinfodata.goodsname }}</span>
								<i v-if="goodsinfodata.isprescription" class="bold i-b f-12 h-20 l-h-16">{{ goodsinfodata.isprescription ? 'RX' : '' }}</i>
								<i v-if="goodsinfodata.isephedrine">{{ goodsinfodata.isephedrine ? '麻黄碱' : '' }}</i>
							</div>
							<div class="cursor flex" @click="addCollection(goodsinfodata.id, goodsinfodata.isfav)">
								<i
									class="c-ffa f-18 m-r-5 iconfont"
									:class="{ 'b2b-shoucang': goodsinfodata.isfav == 0, 'b2b-yishoucang f-16 relative t--2 c-ff8': goodsinfodata.isfav == 1 }"
								></i>
								<p>{{ goodsinfodata.isfav ? '已收藏' : '收藏' }}</p>
							</div>
						</div>

						<div class="flex m-t-10 tc border-b p-b-10" v-if="$store.state.app.token && goodsinfodata.retailprice">
							<div class="bg-f9 p-10-30 rad-6 w23 ">
								<p class="c-fd2 f-26 m-b-10 p-b-10">
									<span class="f-22">￥</span>
									<span class="bold" v-if="batchInfoListitem">{{ batchInfoListitem.price }}</span>
									<span class="bold" v-else>{{ goodsinfodata.price }}</span>
								</p>
								<p>批发价</p>
							</div>
							<div class="bg-f9 p-10-30 rad-6 w23">
								<p class="c-fd2 f-26 m-b-10 p-b-10">
									<span class="f-22">￥</span>
									<span class="bold">{{ goodsinfodata.retailprice }}</span>
								</p>
								<p>建议零售价</p>
							</div>
							<div class="bg-f9 p-10-30 rad-6 w23">
								<p class="c-fd2 f-26 m-b-10 p-b-10">
									<span class="f-22">￥</span>
									<span class="bold">{{ (goodsinfodata.retailprice - batchInfoListitem.price).toFixed(2) }}</span>
								</p>
								<p>毛利</p>
							</div>
							<div class="bg-f9 p-10 rad-6 w23 tc">
								<p class="c-fd2 f-26 m-b-10 p-b-10">
									<span class="bold">
										{{
											(
												((goodsinfodata.retailprice - batchInfoListitem.price) / (batchInfoListitem.retailprice || goodsinfodata.retailprice)).toFixed(4) * 100
											).toFixed(2)
										}}
									</span>
									<span class="f-22">%</span>
								</p>
								<p>毛利率</p>
							</div>
						</div>
						<div class="m-b-10">
							<div class="m-t-5 flex start">
								<!-- <span>促&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;销：</span> -->
								
								<div  v-for="(cuxiao, cuxiaoindex) in goodsinfodata.promotesalesList" :key="cuxiaoindex">
									
									<div class="flex-r start w100">
										<div class="sell i-b c-f p-0-10 f-12 m-r-10 relative o-h rad-50">
											<img class="w-60 h-20 absolute l-10  l-0" src="../../assets/img/cx2.png" alt=""> 
											<span class="relative z-i-10"><span class="bold m-r-10">{{cuxiao.salesname}}</span>{{cuxiao.promoteTitle}}</span>
										</div>
									</div>
									<div class="m-b-10" v-if="cuxiao.pricetype == 5">
										<i class="bg-f00 c-f l-h-20 w-20 h-20 i-b rad-4  i-b tc">赠</i>
										<img class="w-26 h-26" v-if="cuxiao.imgurl" :src="cuxiao.imgurl" />
										<span>腰痛理疗贴</span>
										<span class="c-ff3">{{ cuxiao.promoteTitle }}</span>
									</div>
								</div>
								
							</div>
						</div>

						<div class="parameter ">
							<div class="bg-f5f p-15 flex">
								<div class="flex-1">
									<p>{{goodsview[$store.state.app.goodsvivewtype].tymc}}：{{goodsinfodata[goodsview[$store.state.app.goodsvivewtype].tymcval]}}</p>
									<p>{{goodsview[$store.state.app.goodsvivewtype].zczh}}：{{goodsinfodata[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</p>
									<p>生产厂家：{{ goodsinfodata.manufacturersname }}</p>
									<p>医保编码：{{ goodsinfodata.ybcode }}</p>
									<p>{{goodsview[$store.state.app.goodsvivewtype].bzgg}}：{{goodsinfodata[goodsview[$store.state.app.goodsvivewtype].bzggval]}}</p>
								</div>
								<div class="flex-1">
									<p>产品单位：{{ goodsinfodata.unit }}</p>
									<p>{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{goodsinfodata[goodsview[$store.state.app.goodsvivewtype].ggval]}}</p>
									<p>包装规格：{{ goodsinfodata.pakegespce }}</p>
									<p>{{goodsview[$store.state.app.goodsvivewtype].xh}}：{{goodsinfodata[goodsview[$store.state.app.goodsvivewtype].xhval]}}</p>
									<p v-if="showStock != 0"><span v-if="batchInfoListitem">库存：{{ batchInfoListitem.stock }}</span></p>
								</div>
							</div>
							

							<div class=" p-t-10 relative" v-if="goodsinfodata.batchInfoList">
								<ul class="p-l-70 " v-if="goodsinfodata.batchInfoList.length">
									<span class="h-30 l-h-30 inline m-b-15 absolute l-0 ">选择批号：</span>
									<li v-for="(item, index) in goodsinfodata.batchInfoList" :key="index" @click="celectbatchno(item, index)"
									class="border-a-d h-30 l-h-30 p-0-10 inline m-r-20 cursor m-b-15 relative"
										:class="batchInfoListkey == index ? '--b-a --color' : ''"
									>{{ item.batchno == 'defaultbno' ? '默认分配' : item.batchno }}  / {{item.groupname}} 
										<i v-if="batchInfoListkey == index" class="b2b-xuanze1 iconfont --color absolute r-0 b-2 h-20"></i>
										<!-- <img v-if="batchInfoListkey == index" class="absolute r-0 b-0" src="../../assets/img/ph.png" /> -->
									</li>
								</ul>
							</div>
							<div v-if="batchInfoListitem">
								<p class="m-t-10" v-if="batchInfoListitem.producedate">生产日期：{{ batchInfoListitem.producedate }}</p>
								<p v-if="batchInfoListitem.validdate">有效日期：{{ batchInfoListitem.validdate }}</p>
								<p class="m-t-20" v-if="batchInfoListitem.batchno == 'defaultbno'&&$store.state.app.showprice!=1">
									批发价：
									<span class="c-ff3 ">￥</span>
									<span class="f-30 c-ff3 bold">{{ batchInfoListitem.price }}</span>
								</p>
							</div>
							<p class="m-t-20" v-if="batchInfoListitem.batchno != 'defaultbno' && $store.state.app.token &&$store.state.app.showprice!=1">批发价：
								<span class="c-ff3">￥</span>
								<span class="f-30 c-ff3 bold">{{ batchInfoListitem.price }}</span>
								
							</p>

							<div class="m-t-20 ">
								<div class="h-40  m-r-20 o-h flex m-b-20" >
									<el-input-number  :min="goodsinfodata.fltp?goodsinfodata.fltp:1" :step="goodsinfodata.fltp?goodsinfodata.fltp:1"  size="small" v-model="goodsinfodata.qty" @change="onblur()" :precision="0" :max="batchInfoListitem.stock" ></el-input-number>
								</div>

								<div class="btnaddcart f-16">
									<div >
										<div class="tc addcardbtn --bg" @click="addcarorder(goodsinfodata)" v-if="ishshowcart(goodsinfodata.qty,batchInfoListitem.stock)">
											<i><img src="../../assets/img/gwc_w.png" alt="" /></i>
											<span class="m-l-10" v-if="$store.state.app.Operational">{{ $store.state.app.Operational.goodlist.addcart }}</span>
										</div>
										<div class="tc c-6 rad-2 cursor h-40 l-h-40 w-120 bg-e2e" v-else>
											<span class="h-40 ">库存不足</span>
										</div>
									</div>
								</div>
							</div>
							<p class="c-9 f-12 m-t-10" v-if="goodsinfodata.littleqty != 0 && goodsinfodata.littleqty != 1">注：最小购买添加数量单位为{{ goodsinfodata.littleqty }}</p>
						</div>
					</div>
				</div>

				<!-- 商品详情 -->
				<div class="bg-f det_b">
					<div class=" border-b biaoti f-16">
						<!-- 商品详细 -->
						<span class="m-0-20 cursor p-b-14 c-6" :class="{ 'c-b14e actbo bold': downactive == index }" v-for="(item, index) in det_b" :key="index" @click="btndowm(index)">
							{{ item }}
						</span>
					</div>
					<div class="deta-cent d-f p-0-20">
						<div class="" v-if="downactive==0">
							<div v-html="goodsinfodata.content">{{ goodsinfodata.content }}</div>
						</div>
						<div v-if="downactive==1" class="flex tc">
							<div class=""v-for="(item,index) in certificates">
								<div class="w-300 h-200 m-r-20 p-10 border-a rad-5" >
									<img class="w100 h100" :src="item.imgurl" >
								</div>
								<div class=" l-h-50 f-22">{{item.cname}}</div>
							</div>
						</div>
						<!-- <div v-if="downactive==2" class="deta-cent-left">
							<p>【首营资料】</p>
							<p class="c-ffa  cursor" @click="downloadExcel('')">
								<i><img src="../../assets/img/hdown.png" alt="" /></i>

								下载首营资料
							</p>
							<p>【批检报告】</p>

							<p v-for="(item, index) in batchInfoList" :key="index">
								<span class="w-200 i-b">{{ item.batchno }}批次：</span>
								<span class="c-ffa cursor" @click="downloadExcel(item.batchno)">
									<i><img src="../../assets/img/hdown.png" alt="" /></i>
									下载批检报告
								</span>
							</p>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	<footers/>
	</div>
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	import PicZoom from 'vue-piczoom'
	var allowPlaceAnOrder,showStock
	export default{
		components:{footers,headertop,headers,PicZoom},
		data(){
			showStock = sessionStorage.getItem('showStock'); //
			allowPlaceAnOrder = sessionStorage.getItem('allowPlaceAnOrder');
			return {
				value: 1,
				allactive: false,				//全选
				goodsinfodata: [],				//商品详细
				imageurl: '',				//放大的图
				imageur2: '',				//不放放大的大图
				det_b: ['商品详细','商家资质'],				// '下载中心 '
				downactive: 0,
				centerDialogVisible: false,
				radio: 0,				//选中的采购模板
				TemplateArr: [],				//采购模板
				goodsno: '',
				//加入采购模板的商品编号
				btnimgindex: 0,
				batchInfoListitem:'',// {batchno:'',price: 0},				//当前选择的批号
				batchInfoList: [],//批号列表
				batchInfoListkey: 0,//当前选中得key
				showStock: showStock, // 0不显示库存，空和1显示库存
				pricetype: {'1': '折扣' ,'2' :'立减价格' ,'3' :'秒杀',  '5':'买赠'},
				certificates:'',
				allowPlaceAnOrder:allowPlaceAnOrder,

			}
		},
		
		created() {
			document.documentElement.scrollTop=0
			this.getTenantid(()=> {
				this.getRichtext()
				this.goodsinfo(); //详情
				this.getcertificates()
			})
		
		},
		methods: {
			
			reduce: function reduce(index) {
				console.log('index',index)
				var goodsinfodata = this.goodsinfodata; 
				var value;
				if (goodsinfodata.littleqty > 0) {
					value = index ? goodsinfodata.qty * 1 + goodsinfodata.littleqty : goodsinfodata.qty * 1 - goodsinfodata.littleqty;
					value < goodsinfodata.littleqty ? value = goodsinfodata.littleqty : value = value;
				} else {
					value = index ? goodsinfodata.qty * 1 + 1 : goodsinfodata.qty * 1 - 1;
					value < 1 ? value = 1 : value = value;
				}
		
				// this.goodsinfodata.qty = value;
				// console.log('this.goodsinfodata.qty',this.goodsinfodata.qty)
				this.$set(this.goodsinfodata, 'qty', value)
			},
			
			// 限制最小采购值
			onblur: function onblur() {
				var goodsinfodata = this.goodsinfodata; 
				if (goodsinfodata.littleqty > 0) {
					if (parseInt(goodsinfodata.qty) <= goodsinfodata.littleqty) {
						console.log('小于', parseInt(goodsinfodata.qty));
						this.goodsinfodata.qty = goodsinfodata.littleqty;
					} else {
						console.log('大于', parseInt(goodsinfodata.qty));
						this.goodsinfodata.qty = Math.ceil(parseInt(goodsinfodata.qty) / goodsinfodata.littleqty) * goodsinfodata.littleqty;
					}
				} else {}
			},
			
			
			// 选中小图
			bigimageurl: function bigimageurl(item, index) {
				console.log(item, index);
				this.imageurl = item;
				this.goodsinfodata.imageurl = item;
				this.btnimgindex = index;
			},
			allElection: function allElection() {
				this.allactive = !this.allactive;
			},
			
			// 下载中心和详情点击
			btndowm: function btndowm(i) {
				this.downactive = i;
			},
			
			
			
			
			// 加入购w物车
			addcarorder: function addcarorder(item) {
				var _this = this;
				console.log(item);
				item['batchnosalegroupname']=this.batchInfoListitem.groupname
				item['batchnosalegroupno']=this.batchInfoListitem.groupno
				if(item.isvisible==0){this.$message({message: '您暂无权限',type: 'warning'});;return}
				var showststockn = "库存不足";
				if (showStock != 0) showststockn = '目前库存为' + this.batchInfoListitem.stock; // var batchno=this.batchInfoListitem.batchno=="defaultbno"?'默认批号':this.batchInfoListitem.batchno
				if (item.qty > this.batchInfoListitem.stock) {
					if(allowPlaceAnOrder==1){
						if (this.batchInfoListitem.batchno == "defaultbno") {
							this.$confirm('您购买的【' + item.goodsname + '】、批号：【默认批号】、' + showststockn + '，商品下次到货时间预计为' + item.arrivalday +
								'天，是否继续?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning',
									customClass:this.color,
									callback:function(action){
										if(action=='confirm')_this.addcar(item, _this.batchInfoListitem.batchno, item.qty)
									}
								})
						} else {
							this.$message.error(showststockn + '，无法加入购物车，请修改采购数量');
						}
					}else{
						this.$message.error(showststockn + '，无法加入购物车，请修改采购数量');
					}
				} else {
					this.addcar(item, this.batchInfoListitem.batchno, item.qty)
				}
			},
			
			changestock(index,qty,batindex){
				// .then(res=>{_this.changestock(item.qty)});
				var {goodsinfodata,batchInfoListitem,batchInfoListkey}=this
				console.log(goodsinfodata,batchInfoListitem)
				this.goodsinfodata.batchInfoList[batchInfoListkey].stock=batchInfoListitem.stock-goodsinfodata.qty
				// this.batchInfoListitem.stock=batchInfoListitem.stock-goodsinfodata.qty
				
				
			},
			// 详情POST 开发完成 /goodsfront/goodsinfo  POST /b2bgoods/goodsinfo
			goodsinfo: function goodsinfo() {
				var data = {id: this.$route.query.goodsid,isloadbatch: true,};
				this.requset('/b2bgoods/goodsinfo', data).then((data)=> {
					if (data.status == 'success') {
						this.goodsinfodata = data.data;
						this.goodsinfodata.qty = data.data.fltp?data.data.fltp: data.data.littleqty != '0' ? data.data.littleqty : 1;
						if (data.data.imagesUrlList.length) { 
						} else {
							this.goodsinfodata.imageurl = require('../../assets/img/mr.png');
						}
						this.goodsinfodata.imagesUrlList.push(data.data.imageurl)
						this.goodsinfodata=JSON.parse(JSON.stringify(this.goodsinfodata))
						this.imageurl = data.data.imageurl;
						if (data.data.batchInfoList){
							this.batchInfoListitem = data.data.batchInfoList[0]||'';
							// this.downactive=2
						} 
						this.batchInfoList = data.data.batchInfoList;
						
						
					}
				});
				
			},
			
			getcertificates(){
				this.requset('/baseinfo/certificates', ).then(res=> {
					this.certificates=res.data
					
				})
			},
			
			// 是否显示加入购物车
			ishshowcart(qty,stock){
				console.log(qty,stock)
				if(allowPlaceAnOrder==0){
					if(!stock) return false
					if(qty>stock){
						return false
					}else{
						return true
					}
					
				}else{
					return true
				}
				
			},
			
			
			
			// 【首营资料】下载POST  /b2bgoods/goodsdata
			downloadExcel: function downloadExcel(batchno) {
				
				var orgno = sessionStorage.getItem('orgno');
				var url = server + "/b2bgoods/goodsdata?tenantid=".concat(tenantid, "&orgno=").concat(orgno, "&goodsno=").concat(
					this.goodsinfodata.goodsno) + (batchno !== '' ? '&batchno=' + batchno : '');
				var xhr = new XMLHttpRequest();
				xhr.open('POST', url, true); // 也可以使用POST方式，根据接口
		
				xhr.responseType = "blob"; // 返回类型blob
		
				xhr.onload = function() {
					if (this.status === 200) {
						var blob = this.response;
						var reader = new FileReader();
						reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
		
						reader.onload = function(e) {
							var a = document.createElement('a');
							a.download = genUUID() + (batchno !== '' ? '_' + batchno : '') + '.zip'; //下载文件名
		
							a.href = e.target.result;
							$("body").append(a); // 修复firefox中无法触发click
							a.click();
							$(a).remove();
						};
					}
				}; // 发送ajax请求
				xhr.send();
			},
			
			// 选择批号
			celectbatchno: function celectbatchno(item, index) {
				this.batchInfoListitem = item;
				this.batchInfoListkey = index;
				console.log(item,index)
			},
			
			// 加入收藏
			addCollection: function addCollection(goodsid, type) {
				this.addCollections(goodsid, type).then(res=>{
					if (this.goodsinfodata.id == goodsid) {
						this.goodsinfodata.isfav = this.goodsinfodata.isfav ? 0 : 1;
					}
				})
			}
		}
		
	}
	
	
</script>

<style>
	.mouse-cover-canvas{left: 830px !important;top: 223px !important;}
	.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{font-weight: unset;}
	.o-x::-webkit-scrollbar{
		display: block !important;
		height:8px;
	}
	.o-x::-webkit-scrollbar-thumb{
	    border-radius:6px;
	    background-color:rgba(0,0,0,0.2);
	}
	.o-x::-webkit-scrollbar-track{
		 border-radius:6px;
	    background-color:rgba(0,0,0,0.1);
	    width:10px;
	}
	
</style>
